import React from "react"
import SEO from "../../components/common/seo"
import ActivityView from "../../components/activity/activity-view"
import Paywall from '../../components/paywall/paywall'
import UrlSearch from "../../components/url/url-search"

const ActivityPage = ({location}:any) => (
  <>
    <SEO title="NavAbility Activity Log" />
    <UrlSearch location={location}/>
    {/* <Paywall> */}
      <ActivityView />
    {/* </Paywall> */}
  </>
)

export default ActivityPage
