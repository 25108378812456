import React, { useState } from 'react';
import { useSubscription, gql, useQuery } from '@apollo/client'
import { Client, MutationUpdate, StatusMessage, SUBSCRIPTION_UPDATES} from 'navabilitysdk';
import { INITIAL_STATE } from '../../global/state/schema'

const FILTERS_QUERY = gql`
    query GetFilters {
        filters @client {
            twin {
                userId
                robotId
                sessionId
                variableLabel
                requestId
            }
            swarm {
                environmentIds
                userIds
                robotIds
                sessionIds
            }
        }
    }`;
    
const ActivityView = () => {
    const initialEvents: any[] = []
    const [events, setEvents] = useState(initialEvents)
    const [eventCounter, setEventCounter] = useState(0)
    
    const filterQuery = useQuery(FILTERS_QUERY)
    const filters = filterQuery?.data?.filters || INITIAL_STATE.filters
    const client = Client(filters.twin.userId, filters.twin.robotId, filters.twin.sessionId)

    const appendEvent = (event:any) => {
        setEventCounter((i) => i + 1)
        let newEvents = [{...event.subscriptionData.data.mutationUpdate,key:eventCounter},...events]
        setEvents(newEvents)
    }

    useSubscription<MutationUpdate>(
        gql(SUBSCRIPTION_UPDATES),
        { variables: { client },
        onSubscriptionData: appendEvent}
    );

    return (
        <>
            {events.filter(event => event.requestId.startsWith(filters.twin.requestId)).map(event => 
                <div key={event.key}>
                    {event.timestamp}: {event.state} {event.action} for request '{event.requestId && event.requestId.substring(0,8)}'
                </div>
            )}
        </>
    )
}

export default ActivityView